import React from "react";
import Media from "react-responsive";

import Navbar from "../components/general/navbar/Navbar";
import PageTitle from "../components/general/PageTitle";
import Footer from "../components/general/Footer";

import DesktopFeatured from "../components/desktop/DesktopFeatured";
import ProjectBar from "../components/pages/featured/ProjectBar";

import MobileNavbar from "../components/mobile/MobileNavbar";
import MobilePageTitle from "../components/mobile/MobilePageTitle";
import MobileFooter from "../components/mobile/MobileFooter";
import MobileFeatured from "../components/mobile/MobileFeatured";

const FeaturedProjects = () => {
  return (
    <div>
      <Media query="(max-width: 768px)">
        {(isMobile) =>
          isMobile ? <MobileNavbar /> : <Navbar absolute={false} color="navy" />
        }
      </Media>
      <Media query="(max-width: 768px)">
        {(isMobile) =>
          // isMobile ? <MobilePageTitle title='Featured Projects' /> : <PageTitle title='Featured Projects'/>
          isMobile ? (
            <div className="flex flex-col justify-center items-center text-center p-3">
              <div className="w-full text-center">
                <h1 className="pt-5 pb-5 text-4xl font-display font-regular text-navy">Featured</h1>
              </div>
              <div className="flex justify-between items-center space-x-3 p-5 font-display text-xl text-navy font-semi">
                <a href="/projects" className="underline">Featured</a>
                <a href="/in-progress">Work in Progress</a>
                <a href="future-work">Future Work</a>
                <a href="/portfolio">Completed</a>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center w-full p-3">
              <div className="w-full text-center">
                <h1 className="pt-5 pb-5 text-4xl font-display font-regular text-navy">Featured</h1>
              </div>
              <div className="flex justify-between w-2/3 p-5 font-display text-2xl text-navy font-semi">
                <a href="/projects" className="underline">Featured</a>
                <a href="/in-progress">Work in Progress</a>
                <a href="future-work">Future Work</a>
                <a href="/portfolio">Completed</a>
              </div>
            </div>
          )
        }
      </Media>
      <Media query="(max-width: 768px)">
        {(isMobile) => (isMobile ? <MobileFeatured /> : <DesktopFeatured />)}
      </Media>
      <Media query="(max-width: 768px)">
        {(isMobile) => (isMobile ? <MobileFooter /> : <Footer />)}
      </Media>
    </div>
  );
};

export default FeaturedProjects;
