import React from "react";

const DesktopFeatured = () => {
  return (
    <div>
      <div className="fade-in">
        <div className="flex flex-col">
          <div className="flex font-display px-12 justify-center pb-8">
            <div className="image-container w-3/5 p-12 pt-0 pb-0 pr-2">
              <img
                src={require("../../images/121broadway_long.jpg")}
                className="w-full h-full"
              />
            </div>
            <div className="flex flex-col justify-center text-navy p-5 w-1/3">
              <h1 className="font-semi text-3xl">
                121 Broadway
              </h1>
              <h2 className="font-semi text-lg pt-5">
                City: <span className="font-regular">Cambridge, MA</span> <br />
                Developer: <span className="font-regular">BXP</span> <br />
                Architect:{" "}
                <span className="font-regular">
                  Stantec
                </span>{" "}
                <br />
                General Contractor:{" "}
                <span className="font-regular">Turner</span> <br />
                Size (SF): <span className="font-regular">487,000</span> <br />
                Year: <span className="font-regular">2025</span> <br />
              </h2>
            </div>
          </div>
          <div className="flex font-display px-12 justify-center text-right pb-8">
            <div className="flex flex-col justify-center text-navy p-5 w-auto">
              <h1 className="font-semi text-3xl">Google Cambridge</h1>
              <h2 className="font-semi text-lg pt-5 text-start">
                City: <span className="font-regular">Cambridge, MA</span> <br />
                Developer:{" "}
                <span className="font-regular">Boston Properties</span> <br />
                Architect:{" "}
                <span className="font-regular">Pickard Chilton </span> <br />
                General Contractor: <span className="font-regular">
                  Turner
                </span>{" "}
                <br />
                Size (SF): <span className="font-regular">494,000</span> <br />
                Year: <span className="font-regular">2022</span> <br />
              </h2>
            </div>
            <div className="image-containers w-3/5">
              <img src={require("../../images/new_google.jpeg")} className="" />
            </div>
          </div>
          <div className="flex font-display px-12 justify-center pb-8">
            <div className="image-container w-3/5">
              <img
                src={require("../../images/ragon_new.jpg")}
                className="w-full h-full"
              />
            </div>
            <div className="flex flex-col justify-center text-navy p-5 w-auto">
              <h1 className="font-semi text-3xl">Ragon Institute 2.0</h1>
              <h2 className="font-semi text-lg pt-5">
                City: <span className="font-regular">Cambridge, MA</span> <br />
                Developer:{" "}
                <span className="font-regular">Harvard, MGH, MIT</span> <br />
                Architect: <span className="font-regular">Payette</span> <br />
                General Contractor:{" "}
                <span className="font-regular">Consigli</span> <br />
                Size (SF): <span className="font-regular">323,000</span> <br />
                Year: <span className="font-regular">2024</span> <br />
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopFeatured;
