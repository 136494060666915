import React from "react";
import ProjectSquare from "./MobileProjectSquare";

const MobileFuture = () => {
  return (
    <div>
      <div className="flex flex-col items-center space-y-4">
        <ProjectSquare
          imageUrl={require("../../images/fenwayphaseii.jpeg")}
          name="Fenway Center Phase II"
          loc="Boston, MA"
        />
        <ProjectSquare
          imageUrl={require("../../images/380stuart.jpeg")}
          name="380 Stuart Street"
          loc="Boston, MA"
        />
        <ProjectSquare
          imageUrl={require("../../images/88seaportalt.jpeg")}
          name="88 Seaport"
          loc="Boston, MA"
        />
        <ProjectSquare
          imageUrl={require("../../images/backbaysouth.jpeg")}
          name="Back Bay Tower"
          loc="Boston, MA"
        />
        <ProjectSquare
          imageUrl={require("../../images/volpe.png")}
          name="Volpe C3"
          loc="Cambridge, MA"
        />
      </div>
    </div>
  );
};

export default MobileFuture;
