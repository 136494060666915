import React from "react";
import ProjectSquare from "./MobileProjectSquare";

const MobileProgress = () => {
  return (
    <div>
      <div className="flex flex-col items-center space-y-4">

      <ProjectSquare imageUrl={require('../../images/121broadway_vert.jpg')}
          name='121 Broadway' loc='Cambridge, MA'/>
        <ProjectSquare imageUrl={require('../../images/binney.png')}
          name='290/250 Binney Street' loc='Cambridge, MA'/>
          <ProjectSquare imageUrl={require('../../images/massgeneral.png')}
          name='Mass General Hospital' loc='Boston, MA'/>


        <ProjectSquare imageUrl={require('../../images/harvarderc.png')}
            name='Harvard ERC East & West Labs' loc='Boston, MA'/>
        <ProjectSquare imageUrl={require('../../images/brighamwomens.jpeg')}
          name="Brigham & Women's Faulkner Hospital" loc='Boston, MA'/>
          <ProjectSquare imageUrl={require('../../images/pcnurse.jpg')}
          name="Providence College School of Nursing" loc='Providence, RI'/>


      <ProjectSquare imageUrl={require('../../images/womeninfants.jpeg')}
          name="Women & Infants Hospital" loc='Providence, RI'/>
      <ProjectSquare imageUrl={require('../../images/danoff.jpg')}
          name="Brown University Danoff Laboratory" loc='Providence, RI'/>
      </div>
    </div>
  );
};

export default MobileProgress;
