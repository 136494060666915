import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import "./App.css";
import Featured from "./pages/Featured";
import Careers from "./pages/Careers";
import MeetTheTeam from "./pages/MeetTheTeam";
import Contact from "./pages/ContactTest";
import OurStory from "./pages/OurStory";
import OurMission from "./pages/OurMission";
import OurWork from "./pages/OurWork";
import InProgress from "./pages/InProgress";
import Future from "./pages/Future";

import Project from "./pages/Project";

function App() {
  return (
    <div>
      <BrowserRouter>
        <TransitionGroup>
        <Routes>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/featured-projects" element={<Featured />} />
          <Route path="/home" element={<Home />} />
          <Route path="/our-team" element={<MeetTheTeam />} />
          <Route path="/our-story" element={<OurStory />} />
          <Route path="/our-work" element={<OurWork />} />
          <Route path="/our-mission" element={<OurMission />} />
          <Route path="/projects" element={<Featured />} />
          <Route path="/portfolio" element={<Projects />} />
          <Route path="/in-progress" element={<InProgress />} />
          <Route path="/future-work" element={<Future />} />


          <Route
            path="/providence-college-school-of-nursing"
            element={
              <Project
                images={[
                  'prjimg/pcnurse.jpg'
                ]}
                name="Providence College School of Nursing"
                city="Providence"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/volpe-c3"
            element={
              <Project
                images={[
                  'prjimg/volpe.png'
                ]}
                name="Volpe C3"
                city="Cambridge"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/brown-university-danoff-laboratory"
            element={
              <Project
                images={[
                  'prjimg/danoff.jpg'
                ]}
                name="Brown University Danoff Laboratory"
                city="Providence"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/105-west-1st-street"
            element={
              <Project
                images={[
                  "prjimg/West_1.jpeg",
                  "prjimg/West_2.jpeg",
                  "prjimg/West_3.jpeg",
                  "prjimg/West_4.jpeg",
                  "prjimg/West_5.jpeg",
                ]}
                name="105 West 1st Street"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/380-stuart-street"
            element={
              <Project
                images={["prjimg/380Stuart_1.png"]}
                name="380 Stuart Street"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/121-broadway"
            element={
              <Project
                images={["prjimg/121broadway_long.jpg"]}
                name="121 Broadway"
                city="Cambridge"
                state="Massachusetts"
                type="Residential"
              />
            }
          />
          <Route
            path="/88-seaport"
            element={
              <Project
                images={["prjimg/88seaport.jpeg"]}
                name="88 Seaport"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/888-boylston-street"
            element={
              <Project
              images={['prjimg/Boylston_1.jpeg', 'prjimg/Boylston_2.jpeg', 'prjimg/Boylston_3.jpeg', 'prjimg/Boylston_4.jpeg', 'prjimg/Boylston_5.jpeg']}
                name="88 Boylston Street"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/back-bay-tower"
            element={
              <Project
              images={['prjimg/BackBay_1.jpeg', 'prjimg/BackBay_2.jpeg', 'prjimg/BackBay_3.jpeg', 'prjimg/BackBay_4.jpeg']}
                name="Back Bay Tower"
                city="Boston"
                state="Massachusetts"
                type="Commercial"
              />
            }
          />
          <Route
            path="/boston-medical"
            element={
              <Project
                images={['prjimg/BostonMed_1.jpeg', 'prjimg/BostonMed_2.jpeg', 'prjimg/BostonMed_3.jpeg', 'prjimg/BostonMed_4.jpeg']}
                name="Boston Medical"
                city="Boston"
                state="Massachusetts"
                type="Medical"
              />
            }
          />
          <Route
            path="/boston-university-school-of-dental-medicine"
            element={
              <Project
              images={['prjimg/Dental_1.jpeg', 'prjimg/Dental_5.jpeg', 'prjimg/Dental_6.jpeg']}
                name="Boston University School of Dental Medicine"
                city="Boston"
                state="Massachusetts"
                type="Education"
              />
            }
          />
          <Route
            path="/brigham-&-women's-faulkner-hospital"
            element={
              <Project
              images={['prjimg/Brigham_1.jpeg', 'prjimg/Brigham_2.jpeg', 'prjimg/Brigham_3.jpeg', 'prjimg/Brigham_4.jpeg', 'prjimg/Brigham_5.jpeg']}
                name="Brigham & Women's Faulkner Hospital"
                city="Boston"
                state="Massachusetts"
                type="Medical"
              />
            }
          />
          <Route
            path="/bulfinch-crossing"
            element={
              <Project
              images={['prjimg/Bulfinch_1.jpeg', 'prjimg/Bulfinch_2.jpeg', 'prjimg/Bulfinch_3.jpeg', 'prjimg/Bulfinch_4.jpeg', 'prjimg/Bulfinch_5.jpeg']}
                name="Bulfinch Crossing"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/eataly"
            element={
              <Project
              images={['prjimg/Eataly_1.jpeg']}
                name="Eataly"
                city="Boston"
                state="Massachusetts"
                type="Commercial"
              />
            }
          />
          <Route
            path="/fenway-center-phase-ii"
            element={
              <Project
              images={['prjimg/Fenway_1.jpeg', 'prjimg/Fenway_2.jpeg', 'prjimg/Fenway_3.jpeg', 'prjimg/Fenway_4.jpeg', 'prjimg/Fenway_5.jpeg', 'prjimg/Fenway_6.jpeg', 'prjimg/Fenway_7.jpeg', 'prjimg/Fenway_8.jpeg']}
                name="Fenway Center Phase II"
                city="Boston"
                state="Massachusetts"
                type="Commercial"
              />
            }
          />
          <Route
            path="/harvard-erc-east-&-west-labs"
            element={
              <Project
              images={['/prjimg/HarvardERC_2.jpeg', '/prjimg/HarvardERC_3.jpeg', '/prjimg/HarvardERC_4.jpeg', '/prjimg/HarvardERC_5.jpeg', '/prjimg/HarvardERC_6.jpeg','/prjimg/HarvardERC_7.jpeg']}
                name="Harvard ERC East & West Labs"
                city="Boston"
                state="Massachusetts"
                type="Education"
              />
            }
          />
          <Route
            path="/mass-general-hospital"
            element={
              <Project
              images={['prjimg/massgeneral.png', 'prjimg/MassGeneral_2.jpeg', 'prjimg/MassGeneral_3.jpeg', 'prjimg/MassGeneral_4.jpeg', 'prjimg/MassGeneral_5.jpeg']}
                name="Mass General Hospital"
                city="Boston"
                state="Massachusetts"
                type="Medical"
              />
            }
          />
          <Route
            path="/mass-mutual-@-fan-pier"
            element={
              <Project
              images={['prjimg/MassMutual_1.jpeg', 'prjimg/MassMutual_2.jpeg', 'prjimg/MassMutual_3.jpeg', 'prjimg/MassMutual_4.jpeg', 'prjimg/MassMutual_5.jpeg', 'prjimg/MassMutual_6.jpeg', 'prjimg/MassMutual_7.jpeg']}
                name="Mass Mutual @ Fan Pier"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/pier-4"
            element={
              <Project
              images={['prjimg/Pier_1.jpeg', 'prjimg/Pier_2.jpeg', 'prjimg/Pier_3.jpeg', 'prjimg/Pier_4.jpeg']}
                name="Pier 4"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/twenty-two-liberty-@-fan-pier"
            element={
              <Project
              images={['prjimg/22Liberty_1.jpeg', 'prjimg/22Liberty_2.jpeg', 'prjimg/22Liberty_3.jpeg']}
                name="Twenty Two Liberty @ Fan Pier"
                city="Boston"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/150-2nd-street"
            element={
              <Project
              images={['prjimg/Second150_1.jpeg', 'prjimg/Second150_2.jpeg']}
                name="150 2nd Street"
                city="Cambridge"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/290/250-binney-street"
            element={
              <Project
              images={['/prjimg/Binney_1.jpeg']}
                name="290/250 Binney Street"
                city="Cambridge"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/google-headquarters"
            element={
              <Project
              images={['prjimg/Google_1.png', 'prjimg/Google_2.jpeg', 'prjimg/Google_3.jpeg', 'prjimg/Google_4.jpeg', 'prjimg/Google_5.jpeg', 'prjimg/Google_6.jpeg']}
                name="Google Headquarters"
                city="Cambridge"
                state="Massachusetts"
                type="Office"
              />
            }
          />
          <Route
            path="/mit-site-5"
            element={
              <Project
              images={['prjimg/MIT_1.jpeg', 'prjimg/MIT_2.jpeg', 'prjimg/MIT_3.jpeg', 'prjimg/MIT_4.jpeg']}
                name="MIT Site 5"
                city="Cambridge"
                state="Massachusetts"
                type="Education"
              />
            }
          />
          <Route
            path="/ragon-institute-ii"
            element={
              <Project
              images={['prjimg/Ragon_1.jpeg', 'prjimg/Ragon_2.jpeg', 'prjimg/Ragon_3.jpeg', 'prjimg/Ragon_4.jpeg', 'prjimg/Ragon_5.jpeg', 'prjimg/Ragon_6.jpeg', 'prjimg/Ragon_7.jpeg']}
                name="Ragon Institute II"
                city="Cambridge"
                state="Massachusetts"
                type="Education"
              />
            }
          />
          <Route
            path="/uri-college-of-engineering"
            element={
              <Project
              images={['prjimg/URIEngn_1.jpeg', 'prjimg/URIEngn_2.jpeg', 'prjimg/URIEngn_3.jpeg', 'prjimg/URIEngn_4.jpeg']}
                name="URI College of Engineering"
                city="South Kingston"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/danbury-hospital"
            element={
              <Project
              images={['prjimg/Danbury_1.jpeg', 'prjimg/Danbury_2.jpeg']}
                name="Danbury Hospital"
                city="Danbury"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/quinnipiac-university-wellness-center"
            element={
              <Project
              images={['prjimg/Wellness_1.jpeg', 'prjimg/Wellness_2.jpeg', 'prjimg/Wellness_3.jpeg', 'prjimg/Wellness_4.jpeg']}
                name="Quinnipiac University Wellness Center"
                city="Hamden"
                state="CT"
                type="Education"
              />
            }
          />
          <Route
            path="/foxwoods-resort-hotel-&-casino"
            element={
              <Project
              images={['prjimg/Foxwoods_1.jpeg', 'prjimg/Foxwoods_2.jpeg', 'prjimg/Foxwoods_3.jpeg']}
                name="Foxwoods Resort Hotel & Casino"
                city="Ledyard"
                state="Connecticut"
                type="Commercial"
              />
            }
          />
          <Route
            path="/mgm-grand-at-foxwoods"
            element={
              <Project
              images={['prjimg/MGM_1.jpeg', 'prjimg/MGM_2.jpeg', 'prjimg/MGM_3.jpeg']}
                name="MGM Grand at Foxwoods"
                city="Ledyard"
                state="Connecticut"
                type="Commercial"
              />
            }
          />
          <Route
            path="/100-college-st"
            element={
              <Project
              images={['prjimg/College100_1.jpeg', 'prjimg/College100_2.jpeg', 'prjimg/College100_3.jpeg', 'prjimg/College100_4.jpeg']}
                name="100 College Street"
                city="New Haven"
                state="Connecticut"
                type="Education"
              />
            }
          />
          <Route
            path="/101-college-st"
            element={
              <Project
              images={['prjimg/101College_1.jpeg', 'prjimg/101College_2.jpeg', 'prjimg/101College_3.jpeg', 'prjimg/101College_4.jpeg', 'prjimg/101College_5.jpeg', 'prjimg/101College_6.jpeg', 'prjimg/101College_7.jpeg']}
                name="101 College Street"
                city="New Haven"
                state="Connecticut"
                type="Education"
              />
            }
          />
          <Route
            path="/55-park-street"
            element={
              <Project
              images={['prjimg/Park_1', 'prjimg/Park_2', 'prjimg/Park_3', 'prjimg/Park_4']}
                name="55 Park Street"
                city="New Haven"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/knights-of-columbus"
            element={
              <Project
              images={['prjimg/Knights_1.jpeg', 'prjimg/Knights_2.jpeg', 'prjimg/Knights_3.jpeg']}
                name="Knights of Columbus"
                city="New Haven"
                state="Connecticut"
                type="Office"
              />
            }
          />
          <Route
            path="/yale-new-haven-hospital"
            element={
              <Project
              images={['prjimg/yalenewhaven.jpeg', 'prjimg/YaleNewHaven_2.jpeg']}
                name="Yale New Haven Hospital"
                city="New Haven"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/yale-peabody-museum"
            element={
              <Project
              images={['prjimg/Peabody_1.jpeg', 'prjimg/Peabody_2.jpeg', 'prjimg/Peabody_3.jpeg', 'prjimg/Peabody_4.jpeg', 'prjimg/Peabody_5.jpeg']}
                name="Yale Peabody Museum"
                city="New Haven"
                state="Connecticut"
                type="Commercial"
              />
            }
          />
          <Route
            path="/yale-smilow-cancer-center"
            element={
              <Project
              images={['prjimg/Smilow_1.jpeg', 'prjimg/Smilow_2.jpeg', 'prjimg/Smilow_3.jpeg', 'prjimg/Smilow_4.jpeg', 'prjimg/Smilow_5.jpeg', 'prjimg/Smilow_6.jpeg']}
                name="Yale Smilow Cancer Center"
                city="New Haven"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/norwalk-hospital"
            element={
              <Project
              images={['prjimg/Norwalk_1.jpeg', 'prjimg/Norwalk_2.jpeg', 'prjimg/Norwalk_3.jpeg', 'prjimg/Norwalk_4.jpeg']}
                name="Norwalk Hospital"
                city="Norwalk"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/providence-college-friar-center"
            element={
              <Project
              images={['prjimg/Friar_1', 'prjimg/Friar_2', 'prjimg/Friar_3', 'prjimg/Friar_4', 'prjimg/Friar_5', 'prjimg/Friar_6']}
                name="Providence College Friar Center"
                city="Providence"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/providence-college-school-of-business"
            element={
              <Project
              images={['prjimg/PCBusiness_1.jpeg', 'prjimg/PCBusiness_2.jpeg', 'prjimg/PCBusiness_3.jpeg']}
                name="Providence College School of Business"
                city="Providence"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/providence-place-mall"
            element={
              <Project
              images={['prjimg/ProvPlace_1.jpeg', 'prjimg/ProvPlace_2.jpeg']}
                name="Providence Place Mall"
                city="Providence"
                state="Rhode Island"
                type="Commercial"
              />
            }
          />
          <Route
            path="/south-street-landing"
            element={
              <Project
              images={['prjimg/SSL_1.jpeg', 'prjimg/SSL_2.jpeg', 'prjimg/SSL_3.jpeg', 'prjimg/SSL_4.jpeg']}
                name="South Street Landing"
                city="Providence"
                state="Rhode Island"
                type="Office"
              />
            }
          />
          <Route
            path="/the-dunkin'-donuts-center"
            element={
              <Project
              images={['prjimg/Dunk_1.jpeg', 'prjimg/Dunk_2.jpeg', 'prjimg/Dunk_3.jpeg']}
                name="The Dunkin' Donuts Center"
                city="Providence"
                state="Rhode Island"
                type="Commercial"
              />
            }
          />
          <Route
            path="/women-&-infants-hospital"
            element={
              <Project
              images={['prjimg/WomenInfants_1.jpeg']}
                name="Women & Infants Hospital"
                city="Providence"
                state="Rhode Island"
                type="Medical"
              />
            }
          />
          <Route
            path="/bryant-university-george-e.-bello-center"
            element={
              <Project
              images={['prjimg/Bello_1.jpeg', 'prjimg/Bello_2.jpeg']}
                name="Bryant University George E. Bello Center"
                city="Smithfield"
                state="Rhode Island"
                type="Education"
              />
            }
          />
          <Route
            path="/stamford-hospital"
            element={
              <Project
              images={['prjimg/Stamford_1.jpeg', 'prjimg/Stamford_2.jpeg', 'prjimg/Stamford_3.jpeg', 'prjimg/Stamford_4.jpeg']}
                name="Stamford Hospital"
                city="Stamford"
                state="Connecticut"
                type="Medical"
              />
            }
          />
          <Route
            path="/uconn-gant-renovation"
            element={
              <Project
              images={['prjimg/Gant_1.jpeg', 'prjimg/Gant_2.jpeg', 'prjimg/Gant_3.jpeg', 'prjimg/Gant_4.jpeg', 'prjimg/Gant_5.jpeg', 'prjimg/Gant_6.jpeg']}
                name="UCONN Gant Renovation"
                city="Storrs"
                state="Connecticut"
                type="Education"
              />
            }
          />
          <Route
            path="/uconn-stem-research-center"
            element={
              <Project
              images={['prjimg/UCONNStem_1', 'prjimg/UCONNStem_2']}
                name="UCONN Stem Research Center"
                city="Storrs"
                state="Connecticut"
                type="Education"
              />
            }
          />
          <Route
            path="/mohegan-sun-earth-hotel"
            element={
              <Project
              images={['prjimg/Earth.jpeg', 'prjimg/Earth_2.jpeg', 'prjimg/Earth_3.jpeg', 'prjimg/Earth_4.jpeg']}
                name="Mohegan Sun Earth Hotel"
                city="Uncasville"
                state="Connecticut"
                type="Commercial"
              />
            }
          />
          <Route
            path="/mohegan-sun-tower-hotel"
            element={
              <Project
              images={['prjimg/Tower_1.jpeg', 'prjimg/Tower_2.jpeg', 'prjimg/Tower_3.jpeg', 'prjimg/Tower_4.jpeg']}
                name="Mohegan Sun Tower Hotel"
                city="Uncasville"
                state="Connecticut"
                type="Commercial"
              />
            }
          />
          <Route
            path="/white-plains-hospital-medical-center"
            element={
              <Project
              images={['prjimg/WhitePlains_1.jpeg', 'prjimg/WhitePlains_2.jpeg', 'prjimg/WhitePlains_3.jpeg', 'prjimg/WhitePlains_4.jpeg', 'prjimg/WhitePlains_5.jpeg']}
                name="White Plains Hospital Medical Center"
                city="White Plains"
                state="New York"
                type="Medical"
              />
            }
          />
        </Routes>
        </TransitionGroup>
      </BrowserRouter>
    </div>
  );
}

export default App;
