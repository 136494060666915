import React from 'react'
import ProjectSquare from '../pages/projects/ProjectSquare'

const DesktopFuture = () => {
  return (
    <div>
      <div className='flex justify-center space-x-4 mt-4'>
        <ProjectSquare imageUrl={require('../../images/fenwayphaseii.jpeg')}
          name="Fenway Center Phase II" loc='Boston, MA'/>
        <ProjectSquare imageUrl={require('../../images/380stuart.jpeg')}
          name='380 Stuart Street' loc='Boston, MA'/>
        <ProjectSquare imageUrl={require('../../images/88seaportalt.jpeg')}
          name='88 Seaport' loc='Boston, MA'/>
      </div>
      <div className='flex justify-center space-x-4 mt-4'>
        <ProjectSquare imageUrl={require('../../images/backbaysouth.jpeg')}
          name='Back Bay Tower' loc='Boston, MA'/>
        <ProjectSquare imageUrl={require('../../images/volpe.png')}
          name='Volpe C3' loc='Cambridge, MA'/>
      </div>
    </div>
  )
}

export default DesktopFuture