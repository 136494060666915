import React from 'react'
import Media from 'react-responsive'

import Navbar from '../components/general/navbar/Navbar'
import PageTitle from '../components/general/PageTitle'
import Footer from '../components/general/Footer'

import DesktopProjects from '../components/desktop/DesktopProjects'

import MobileNavbar from '../components/mobile/MobileNavbar'
import MobilePageTitle from '../components/mobile/MobilePageTitle'
import MobileFooter from '../components/mobile/MobileFooter'
import MobileProjects from '../components/mobile/MobileProjects'


const Projects = () => {

  return (
    <div className='flex flex-col'>
      <div>
      <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ? <MobileNavbar /> : <Navbar absolute={false} color='navy' />
        )}
      </Media>
      </div>
      <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ?
          <div className="flex flex-col justify-center items-center text-center p-3">
              <div className="w-full text-center">
                <h1 className="pt-5 pb-5 text-4xl font-display font-regular text-navy">Completed</h1>
              </div>
              <div className="flex justify-between items-center space-x-3 p-5 font-display text-xl text-navy font-semi">
                <a href="/projects">Featured</a>
                <a href="/in-progress">Work in Progress</a>
                <a href="future-work">Future Work</a>
                <a href="/portfolio" className="underline">Completed</a>
              </div>
            </div>
            :
          <div className="flex flex-col justify-center items-center w-full p-2">
              <div className="w-full text-center">
                <h1 className="pt-5 pb-10 text-4xl font-display font-regular text-navy">Completed</h1>
              </div>
              <div className="flex justify-between w-2/3 pb-3 font-display text-2xl text-navy font-semi">
                <a href="/projects">Featured</a>
                <a href="/in-progress">Work in Progress</a>
                <a href="/future-work">Future Work</a>
                <a href="/portfolio" className='underline'>Completed</a>
              </div>
            </div>
        )}
      </Media>
      <div>
      </div>
      <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ? <MobileProjects /> : <DesktopProjects />
        )}
      </Media>
      <div className='mt-8'>
      <Media query="(max-width: 768px)">
        {isMobile => (
          isMobile ? <MobileFooter /> : <Footer />
        )}
      </Media>
      </div>
    </div>
  )
}

export default Projects