import React, { useState } from "react";
import Media from "react-responsive";

import Navbar from "../components/general/navbar/Navbar";
import MobileNavbar from "../components/mobile/MobileNavbar";
import PageTitle from "../components/general/PageTitle";
import Footer from "../components/general/Footer";
import MobileFooter from "../components/mobile/MobileFooter";
import MobileProjectTitle from "../components/mobile/MobileProjectTitle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Project = ({ images, name, city, state, type }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fadeState, setFadeState] = useState("fade-in");

  const handleNextImage = () => {
    setFadeState("fade-out");
    setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFadeState("fade-in");
    }, 300);
  };

  const handlePrevImage = () => {
    setFadeState("fade-out");
    setTimeout(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
      setFadeState("fade-in");
    }, 300);
  };

  return (
    <div className="flex flex-col justify-between items-center font-display text-navy h-screen">
  <Media query="(max-width: 768px)">
    {(isMobile) =>
      isMobile ? <MobileNavbar title={name} /> : <Navbar color="navy" />
    }
  </Media>

  <Media query="(max-width: 768px)">
    {(isMobile) =>
      isMobile ? <MobileProjectTitle title={name} /> : <PageTitle title={name} />
    }
  </Media>

  <div className="flex flex-grow justify-center w-full">
    <div className="flex flex-col justify-around w-full max-w-4xl px-4 relative">
      <div className="flex justify-center items-center w-full mb-4">
        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 text-lg hover:text-gray-600 z-10"
          onClick={handlePrevImage}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <div className="aspect-w-16 aspect-h-9 w-full max-w-lg">
          <img
            className={`object-contain w-full h-full max-h-[400px] max-w-[600px] px-4 transition-opacity duration-300 ease-in-out ${fadeState}`}
            src={images[currentImageIndex]}
            alt={`Project ${currentImageIndex + 1}`}
          />
        </div>

        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 text-lg hover:text-gray-600 z-10"
          onClick={handleNextImage}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <div className="flex flex-col items-start w-full">
        <div className="flex justify-between w-full text-base font-semi">
          <div className="flex-1 text-right mr-2">Location:</div>
          <div className="flex-1 text-left">{city}, {state}</div>
        </div>
        <div className="flex justify-between w-full text-base font-semi mt-1">
          <div className="flex-1 text-right mr-2">Type:</div>
          <div className="flex-1 text-left">{type}</div>
        </div>
      </div>
    </div>
  </div>

  <Media query="(max-width: 768px)">
    {(isMobile) =>
      isMobile ? (
        <MobileFooter />
      ) : (
        <div className="mt-4 w-full">
          <Footer />
        </div>
      )
    }
  </Media>
</div>

  );
};

export default Project;
